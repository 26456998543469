const coins = [
  {
    id: 18,
    name: "Polygon Matic",
    preffix: "MATIC",
    image: "polygon.png",
    min: "10",
    active: true,
    test: false,
    network: "MATIC",
  },
  {
    id: 19,
    name: "Shiba Inu",
    preffix: "SHIB",
    image: "shib.png",
    min: "1000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 20,
    name: "Indian Rupees",
    preffix: "INR",
    image: "inr.png",
    min: "1000",
    active: true,
    test: false,
    network: "FIAT",
  },
  {
    id: 16,
    name: "Binance USD",
    preffix: "BUSD",
    image: "busd.png",
    min: "10.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 15,
    name: "USDC",
    preffix: "USDC",
    image: "usdc.png",
    min: "10.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 14,
    name: "TrueUSD",
    preffix: "TUSD",
    image: "tusd.png",
    min: "10.00000000",
    active: true,
    test: false,
    network: "ERC-20",
  },
  //   {
  //     id: 13,
  //     name: "Maker",
  //     preffix: "MKR",
  //     image: "mkr.png",
  //     min: "0.00200000",
  //     active: true,
  //     test: false,
  //     network: "ERC-20",
  //   },
  {
    id: 12,
    name: "NEXO",
    preffix: "NEXO",
    image: "nexo.png",
    min: "10.000000",
    active: true,
    test: false,
    network: "ERC-20",
  },
  //   {
  //     id: 11,
  //     name: "Pax USD",
  //     preffix: "USDP",
  //     image: "usdp.png",
  //     min: "1.00000000",
  //     active: true,
  //     test: false,
  //     network: "ERC-20",
  //   },
  {
    id: 10,
    name: "Binance",
    preffix: "BNB",
    image: "bnb.png",
    min: "0.03",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 9,
    name: "Ripple",
    preffix: "XRP",
    image: "xrp.png",
    min: "25.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 8,
    name: "Cardano",
    preffix: "ADA",
    image: "ada.png",
    min: "25.00000000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 7,
    name: "Dogecoin",
    preffix: "DOGE",
    image: "doge.png",
    min: "120.00000",
    active: true,
    test: false,
    network: "BSC",
  },
  {
    id: 6,
    name: "TRON",
    preffix: "TRX",
    image: "tron.png",
    min: "140.00000",
    active: true,
    test: false,
    network: "TRX",
  },
  {
    id: 5,
    name: "Tether",
    preffix: "USDT",
    image: "tether.png",
    min: "10.00000000",
    active: true,
    test: false,
    network: "ERC-20",
  },
  {
    id: 4,
    name: "Bitcoin Cash",
    preffix: "BCH",
    image: "bch.png",
    min: "0.750000",
    active: true,
    test: false,
    network: "BCH",
  },
  {
    id: 3,
    name: "Litcoin",
    preffix: "LTC",
    image: "ltc.png",
    min: "0.100000",
    active: true,
    test: false,
    network: "LTC",
  },
  {
    id: 2,
    name: "Ethereum",
    preffix: "ETH",
    image: "eth.png",
    min: "0.0050000",
    active: true,
    test: false,
    network: "ETH",
  },
  {
    id: 1,
    name: "Bitcoin",
    preffix: "BTC",
    image: "btc.png",
    min: "0.00008000",
    active: true,
    test: false,
    network: "BTC",
  },
  {
    id: 17,
    name: "Bitrubix",
    preffix: "BRX",
    image: "brx.png",
    min: "220",
    active: true,
    test: false,
    network: "BSC",
  },
];

export default coins;
